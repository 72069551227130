import { PageLayoutFooter } from '@ferreri/prever-component-lib';

import { Hub } from '../modules/hub/Hub';
import { PAGE_TITLE } from '../utils/pageTitle';

export default function HubPage() {
  return (
    // FIXME: ValidSessionRedirectRoute
    // <ValidSessionRedirectRoute
    //   redirectUrl={HUB_HOME}
    //   loadingComponent={<SkeletonHomePage />}
    // >
    <PageLayoutFooter title={PAGE_TITLE.landingPage}>
      <Hub />
    </PageLayoutFooter>
    // </ValidSessionRedirectRoute>
  );
}
