import {
  PreverAngelusFillXLargeIcon,
  ProductClubeFillXLargeIcon,
  ProductHealthFillXLargeIcon,
  ProductPosthumousFillXLargeIcon,
  ProductPetFillLargeIcon,
  token,
} from '@ferreri/prever-component-lib';

import { CLUBE, CONTRACT, HEALTH, POSTHUMOUS, TELEVET } from '../routes';

export const SERVICES_WEB = {
  plans: {
    icon: <PreverAngelusFillXLargeIcon color={token.colors.neutralXxDark} />,
    title: 'Plano',
    description: 'Gerencie seu plano de forma rápida e descomplicada!',
    uri: CONTRACT,
  },
  clube: {
    icon: (
      <ProductClubeFillXLargeIcon color={token.colors.solidClubePrimaryPure} />
    ),
    title: 'Clube de Vantagens',
    description:
      'Um clube com descontos exclusivos em produtos e serviços de grandes marcas e parceiros locais.',
    uri: CLUBE,
  },
  health: {
    icon: (
      <ProductHealthFillXLargeIcon color={token.colors.solidSaudePrimaryPure} />
    ),
    title: 'Cuidados e Saúde',
    description:
      'Guia médico e de farmácias, teleconsultas e descontos especiais na área da saúde.',
    uri: HEALTH,
  },
  posthumous: {
    icon: (
      <ProductPosthumousFillXLargeIcon
        color={token.colors.solidPostumosPrimaryPure}
      />
    ),
    title: 'Serviços Funerários',
    description:
      'Excelência e humanização no atendimento funeral com cobertura completa.',
    uri: POSTHUMOUS,
  },
  pet: {
    icon: <ProductPetFillLargeIcon color={token.colors.solidPetPrimaryPure} />,
    title: 'Para seu Pet',
    description:
      'Serviços para cuidar de todos os momentos da vida do seu pet.',
    uri: TELEVET,
  },
};
