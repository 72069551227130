import styled from '@emotion/styled';
import {
  ButtonNormalPrimaryMedium,
  LinkWrapper,
  TextHeading2Alt,
  token,
} from '@ferreri/prever-component-lib';
import { useRouter } from 'next/router';

import { AUTH_IDENTIFICATION } from '../routes';

type BackgroundImageProps = {
  source: string;
};

export const Banner = () => {
  const router = useRouter();
  const source = `${router.basePath}/assets/svg/logo.svg`;

  return (
    <>
      <BackgroundImage source={source}>
        <Container>
          <TextHeading2AltStyled>
            Venha para o Angeplus e aproveite todos os benefícios de ser nosso
            associado.
          </TextHeading2AltStyled>
          <LinkWrapper href={AUTH_IDENTIFICATION}>
            <ButtonNormalPrimaryMedium>
              Conhecer o Angeplus
            </ButtonNormalPrimaryMedium>
          </LinkWrapper>
        </Container>
      </BackgroundImage>
    </>
  );
};

const BackgroundImage = styled.div<BackgroundImageProps>`
  margin-bottom: -${token.spacing.spacingXxSmall};
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 301px;
  background-color: ${token.colors.solidAngeplusForeground};
  background-image: ${({ source }) => `url(${source})`};
  background-repeat: no-repeat;
  background-position: center;
  padding: ${token.spacing.spacingMedium};
`;

const Container = styled.div`
  display: flex;
  gap: ${token.spacing.spacingMedium};
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: 400px;
`;

const TextHeading2AltStyled = styled(TextHeading2Alt)`
  text-align: center;
`;
