import { CONTRACT, PLAN } from '../routes';

export const PLAN_BENEFITS = [
  {
    title: 'Torne-se um associado',
    content:
      'Aqui você encontra um plano que é perfeito para você e quem você ama.',
    items: [
      'Referência em assistência familiar no Sul do Brasil',
      'Benefícios em vida para titular e dependentes',
      'Múltiplos seguros e assistências',
    ],
    uri: PLAN,
    buttonLabel: 'Adquirir plano',
  },
  {
    title: 'Cuide do seu plano',
    content:
      'Autonomia para consultar e gerir seu plano a qualquer momento e lugar.',
    items: [
      'Consulte a cobertura do seu plano',
      'Acesse sua carteirinha digital',
      'Gerencie suas mensalidades e formas de pagamentos',
    ],
    uri: CONTRACT,
    buttonLabel: 'Ir para Meu Plano',
  },
];
