import {
  ButtonNormalPrimaryMedium,
  Content,
  DividerHorizontal,
  LinkWrapper,
  SectionInstallPWA,
} from '@ferreri/prever-component-lib';

import { AUTH_IDENTIFICATION } from '../routes';
import { Banner } from './Banner';
import { HubHero } from './HubHero';
import { PlanBenefitsSection } from './PlanBenefitsSection';
import { ServicesWebSection } from './ServicesWebSection';
import { SummarySection } from './SummarySection';

export const Hub = () => {
  return (
    <>
      <SectionInstallPWA />
      <HubHero title="Várias soluções em um só lugar!">
        <LinkWrapper href={AUTH_IDENTIFICATION}>
          <ButtonNormalPrimaryMedium>
            Entrar no Angeplus
          </ButtonNormalPrimaryMedium>
        </LinkWrapper>
      </HubHero>
      <Content>
        <SummarySection />
        <DividerHorizontal />
        <PlanBenefitsSection />
        <DividerHorizontal />
        <ServicesWebSection />
      </Content>
      <Banner />
    </>
  );
};
