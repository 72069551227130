import {
  CardButtonMedium,
  GroupElementGrid,
  LinkWrapper,
} from '@ferreri/prever-component-lib';

import { SERVICES_WEB } from './servicesWeb';

export const ServicesWebCardButtonList = () => {
  return (
    <GroupElementGrid>
      {Object.entries(SERVICES_WEB).map(
        ([key, { icon, description, title, uri }]) => (
          <LinkWrapper href={uri} key={key}>
            <CardButtonMedium
              icon={icon}
              title={title}
              description={description}
            />
          </LinkWrapper>
        ),
      )}
    </GroupElementGrid>
  );
};
