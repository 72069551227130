import styled from '@emotion/styled';
import {
  ButtonNormalSecondarySmall,
  CardInfo,
  GroupElement,
  LinkWrapper,
  TextParagraph,
  token,
} from '@ferreri/prever-component-lib';

import { PlanItem } from './PlanItem';

type Benefits = {
  title: string;
  content: string;
  items: string[];
  uri: string;
  buttonLabel: string;
};

type PlanBenefitsCardInfoListItemProps = {
  benefits: Benefits;
};

export const PlanBenefitsCardInfoListItem = (
  props: PlanBenefitsCardInfoListItemProps,
) => {
  return (
    <CardInfo title={props.benefits?.title}>
      <GroupElement>
        <Container>
          <TextParagraph>{props.benefits?.content}</TextParagraph>
          {props.benefits?.items.map((item) => (
            <PlanItem key={item}>{item}</PlanItem>
          ))}
        </Container>
        <LinkWrapper href={props.benefits.uri}>
          <ButtonNormalSecondarySmall>
            {props.benefits?.buttonLabel}
          </ButtonNormalSecondarySmall>
        </LinkWrapper>
      </GroupElement>
    </CardInfo>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${token.spacing.spacingXxSmall};
`;
