import styled from '@emotion/styled';
import {
  token,
  CheckCircleOutlineSmallIcon,
  TextParagraph,
} from '@ferreri/prever-component-lib';

type PlanItemProps = {
  children: React.ReactNode;
};

export const PlanItem = (props: PlanItemProps) => {
  return (
    <Wrapper>
      <CheckCircleOutlineSmallIcon color={token.colors.feedbackSuccessLight} />
      <TextParagraph>{props.children}</TextParagraph>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  gap: ${token.spacing.spacingXxSmall};
`;
