import styled from '@emotion/styled';
import {
  AngeplusLightLogo,
  Hero,
  TextDisplayAlt,
  token,
} from '@ferreri/prever-component-lib';
import { useRouter } from 'next/router';

import { ReactNode } from 'react';

type HubHeroProps = {
  children: ReactNode;
  title: string;
};

export const HubHero = (props: HubHeroProps) => {
  const router = useRouter();
  const source = {
    uri: `${router.basePath}/assets/img/medium-shot-smiley-family-with-phone.webp`,
  };

  return (
    <Hero
      source={source}
      header={<AngeplusLightLogo height={48} width={120} />}
    >
      <SectionHero>
        <TextDisplayAlt>{props.title}</TextDisplayAlt>
        {props.children}
      </SectionHero>
    </Hero>
  );
};

const SectionHero = styled.div`
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: ${token.spacing.spacingSmall};
`;
